import { debounce } from 'lodash';

const DEBOUNCE_TIMEOUT = 100;
const TIMEOUT = 500;
export const NEW_MESSAGE_TIMEOUT = 400;

export const disableElement = element => {
  if (element) {
    element.disabled = true;
  }
};

export const enableElement = element => {
  if (element) {
    element.disabled = false;
  }
};

export const focusElement = element => {
  if (element) {
    element.focus();
  }
};

export const focusInteractiveElement = element => {
  let timeoutId;
  debounce(() => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      focusElement(element);
    }, TIMEOUT);
  }, DEBOUNCE_TIMEOUT)();
};

export const replyAreaFocusHandler = element => {
  let timeoutId;
  let safariTimeout;

  debounce(() => {
    clearTimeout(timeoutId);
    clearTimeout(safariTimeout);
    if (element.disabled !== true) {
      element.blur();
      disableElement(element);
    }
    timeoutId = setTimeout(() => {
      enableElement(element);
      focusElement(element);
    }, TIMEOUT);
  }, DEBOUNCE_TIMEOUT)();
};
