<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <ps-universal-nav class="c-app-bar" :project="account && account.id">
    <router-link
      v-slot="{ href, navigate }"
      :to="primaryMenuItems[0].toState"
      custom
    >
      <ps-nav-logo
        slot="logo"
        name="agentAssist"
        :href="href"
        @click="navigate"
      >
        Contact Center <span slot="suffix">for {{ account.name }}</span>
      </ps-nav-logo>
    </router-link>

    <ps-nav-links slot="navigation">
      <router-link
        v-for="item in primaryMenuItems"
        :key="item.toState"
        v-slot="{ href, isActive, navigate }"
        :to="item.toState"
        custom
      >
        <ps-nav-link :href="href" :active="isActive" @click="navigate">
          {{ $t(`SIDEBAR.${item.label}`) }}
        </ps-nav-link>
      </router-link>
    </ps-nav-links>
    <ps-tag
      id="status-dropdown-trigger"
      slot="actions"
      interactive
      :variant="getStatusLabelVariant"
      size="medium"
      style="text-transform: capitalize;"
    >
      <span class="capitalize">{{ currentUserAvailability }}</span>
      <ps-icon name="chevron-down" />
    </ps-tag>
    <app-bar-controls
      slot="actions"
      :account-id="accountId"
      @open-notification-panel="openNotificationPanel"
      @toggle-accounts="toggleAccountModal"
      @key-shortcut-modal="toggleKeyShortcutModal"
    />
    <ps-divider slot="actions" vertical style="height: 50px" />
    <ps-universal-user-dropdown-trigger />
    <ps-dropdown
      id="status-dropdown"
      trigger="status-dropdown-trigger"
      placement="bottom-start"
    >
      <ps-menu>
        <ps-menu-header>
          {{ $t('SIDEBAR.SET_AVAILABILITY_TITLE') }}
        </ps-menu-header>
        <ps-menu-item
          v-for="status in availabilityStatuses"
          :key="status.value"
          :selected="status.disabled"
          @click="changeAvailabilityStatus(status.value)"
        >
          <ps-icon slot="prefix" name="dot" :style="{ color: status.color }" />
          {{ status.label }}
        </ps-menu-item>
      </ps-menu>
    </ps-dropdown>
    <ps-universal-user-dropdown
      :hideMyAccount="true"
      @logout="logout"
      @selection-change="onSelectionChange"
    >
      <ps-menu-item
        v-if="showChangeAccountOption"
        slot="dropdown-bottom"
        @click="toggleAccountModal"
      >
        {{ $t('SIDEBAR_ITEMS.CHANGE_ACCOUNTS') }}
      </ps-menu-item>
      <ps-menu-item slot="dropdown-bottom" @click="onPreferencesClick">
        {{ $t('SIDEBAR_ITEMS.PROFILE_SETTINGS') }}
      </ps-menu-item>
      <ps-menu-item
        v-if="globalConfig.chatwootInboxToken"
        slot="dropdown-bottom"
        @click="toggleSupportChatWindow"
      >
        {{ $t('SIDEBAR_ITEMS.CHANGE_ACCOUNTS') }}
      </ps-menu-item>
      <ps-menu-item slot="dropdown-bottom" @click="toggleKeyShortcutModal">
        {{ $t('SIDEBAR_ITEMS.KEYBOARD_SHORTCUTS') }}
      </ps-menu-item>
      <ps-menu-item slot="dropdown-bottom">
        <a
          href="/super_admin"
          class="c-app-bar__link"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          {{ $t('SIDEBAR_ITEMS.SUPER_ADMIN_CONSOLE') }}
        </a>
      </ps-menu-item>
    </ps-universal-user-dropdown>
  </ps-universal-nav>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from '../../mixins/isAdmin';
import { getSidebarItems } from './config/default-sidebar';
import alertMixin from 'shared/mixins/alertMixin';
import AppBarControls from './sidebarComponents/AppBarControls.vue';
import Auth from '../../api/auth';

import {
  hasPressedAltAndCKey,
  hasPressedAltAndRKey,
  hasPressedAltAndSKey,
  hasPressedAltAndVKey,
  hasPressedCommandAndForwardSlash,
  isEscape,
} from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import router from '../../routes';
import wootConstants from 'dashboard/constants/globals';
import {
  PYPESTREAM_ANALYTICS_EVENTS,
  captureCustomAnalyticsPypestreamEvent,
} from '../../helper/AnalyticsPypestreamHelper';

const { AVAILABILITY_STATUS_KEYS } = wootConstants;

export default {
  components: {
    AppBarControls,
  },
  mixins: [adminMixin, alertMixin, eventListenerMixins],

  props: {
    showSecondarySidebar: {
      type: Boolean,
      default: true,
    },
    sidebarClassName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOptionsMenu: false,
      isMobileMenuVisible: false,
      isUpdating: false,
    };
  },

  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      account: 'getCurrentAccount',
      currentRole: 'getCurrentRole',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      inboxes: 'inboxes/getInboxes',
      isACustomBrandedInstance: 'globalConfig/isACustomBrandedInstance',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      isOnChatwootCloud: 'globalConfig/isOnChatwootCloud',
      labels: 'labels/getLabelsOnSidebar',
      teams: 'teams/getMyTeams',
      getCurrentUserAvailability: 'getCurrentUserAvailability',
    }),
    activeCustomView() {
      if (this.activePrimaryMenu.key === 'contacts') {
        return 'contact';
      }
      if (this.activePrimaryMenu.key === 'conversations') {
        return 'conversation';
      }
      return '';
    },
    customViews() {
      return this.$store.getters['customViews/getCustomViewsByFilterType'](
        this.activeCustomView
      );
    },
    isConversationOrContactActive() {
      return (
        this.activePrimaryMenu.key === 'contacts' ||
        this.activePrimaryMenu.key === 'conversations'
      );
    },
    sideMenuConfig() {
      return getSidebarItems(this.accountId);
    },
    primaryMenuItems() {
      const menuItems = this.sideMenuConfig.primaryMenu;
      return menuItems.filter(menuItem => {
        const isAvailableForTheUser = menuItem.roles.includes(this.currentRole);

        if (!isAvailableForTheUser) {
          return false;
        }

        if (menuItem.featureFlag) {
          return this.isFeatureEnabledonAccount(
            this.accountId,
            menuItem.featureFlag
          );
        }
        return true;
      });
    },
    activeSecondaryMenu() {
      const { secondaryMenu } = this.sideMenuConfig;
      const { name: currentRoute } = this.$route;

      const activeSecondaryMenu =
        secondaryMenu.find(menuItem =>
          menuItem.routes.includes(currentRoute)
        ) || {};
      return activeSecondaryMenu;
    },
    activePrimaryMenu() {
      const activePrimaryMenu =
        this.primaryMenuItems.find(
          menuItem => menuItem.key === this.activeSecondaryMenu.parentNav
        ) || {};
      return activePrimaryMenu;
    },
    showChangeAccountOption() {
      if (this.globalConfig.createNewAccountFromDashboard) {
        return true;
      }

      const { accounts = [] } = this.currentUser;
      return accounts.length > 1;
    },
    currentUserAvailability() {
      return this.getCurrentUserAvailability;
    },
    availabilityStatuses() {
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST').map(
        (statusLabel, index) => ({
          label: statusLabel,
          value: AVAILABILITY_STATUS_KEYS[index],
          disabled:
            this.currentUserAvailability === AVAILABILITY_STATUS_KEYS[index],
          color: this.getColor(index),
        })
      );
    },
    getStatusLabelVariant() {
      switch (this.currentUserAvailability) {
        case 'online':
          return 'success';
        case 'busy':
          return 'warning';
        default:
          return 'default';
      }
    },
  },

  watch: {
    activeCustomView() {
      this.fetchCustomViews();
    },
    $route() {
      this.isMobileMenuVisible = false;
    },
  },
  mounted() {
    this.$store.dispatch('labels/get');
    this.$store.dispatch('inboxes/get');
    this.$store.dispatch('notifications/unReadCount');
    this.$store.dispatch('teams/get');
    this.$store.dispatch('attributes/get');
    this.fetchCustomViews();
  },
  methods: {
    fetchCustomViews() {
      if (this.isConversationOrContactActive) {
        this.$store.dispatch('customViews/get', this.activeCustomView);
      }
    },
    toggleKeyShortcutModal() {
      this.$emit('open-key-shortcut-modal');
    },
    closeKeyShortcutModal() {
      this.$emit('close-key-shortcut-modal');
    },
    handleKeyEvents(e) {
      if (hasPressedCommandAndForwardSlash(e)) {
        this.toggleKeyShortcutModal();
      }
      if (isEscape(e)) {
        this.closeKeyShortcutModal();
      }

      if (hasPressedAltAndCKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('home')) {
          router.push({ name: 'home' });
        }
      } else if (hasPressedAltAndVKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('contacts_dashboard')) {
          router.push({ name: 'contacts_dashboard' });
        }
      } else if (hasPressedAltAndRKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('settings_account_reports')) {
          router.push({ name: 'settings_account_reports' });
        }
      } else if (hasPressedAltAndSKey(e)) {
        if (!this.isCurrentRouteSameAsNavigation('agent_list')) {
          router.push({ name: 'agent_list' });
        }
      }
    },
    isCurrentRouteSameAsNavigation(routeName) {
      return this.$route.name === routeName;
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    toggleAccountModal() {
      this.$emit('toggle-account-modal');
    },
    showAddLabelPopup() {
      this.$emit('show-add-label-popup');
    },
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },
    toUpper(str) {
      return str
        .toLowerCase()
        .split(' ')
        .map(word => {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join(' ');
    },
    toggleMobileNav(e) {
      e.preventDefault();
      this.isMobileMenuVisible = !this.isMobileMenuVisible;
    },
    onClickAway() {
      this.isMobileMenuVisible = false;
    },
    logout() {
      Auth.logout();
    },
    onPreferencesClick() {
      this.$router.push(`/app/accounts/${this.accountId}/profile/settings`);
    },
    onSelectionChange(e) {
      const {
        detail: { selection },
      } = e;
      const BASE_ADMIN_URL =
        window?.contactCenterConfig?.adminBaseUrl ||
        'https://admin.candidate.pypestream.dev';
      window.open(
        `${BASE_ADMIN_URL}/organization/${selection}/users-management`,
        '_blank'
      );
    },
    changeAvailabilityStatus(availability) {
      if (this.isUpdating) {
        return;
      }

      this.isUpdating = true;
      try {
        this.$store
          .dispatch('updateAvailability', {
            availability,
            account_id: this.accountId,
          })
          .then(() => {
            this.onStatusChangeAnalyticsEvent(availability);
          });
      } catch (error) {
        this.showAlert(
          this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.SET_AVAILABILITY_ERROR')
        );
      } finally {
        this.isUpdating = false;
      }
    },
    onStatusChangeAnalyticsEvent(status) {
      switch (status) {
        case 'online':
          captureCustomAnalyticsPypestreamEvent({
            eventName: PYPESTREAM_ANALYTICS_EVENTS.SET_USER_STATUS_ONLINE,
            isConversationEvent: false,
          });
          break;
        case 'busy':
          captureCustomAnalyticsPypestreamEvent({
            eventName: PYPESTREAM_ANALYTICS_EVENTS.SET_USER_STATUS_BUSY,
            isConversationEvent: false,
          });
          break;
        case 'offline':
          captureCustomAnalyticsPypestreamEvent({
            eventName: PYPESTREAM_ANALYTICS_EVENTS.SET_USER_STATUS_OFFLINE,
            isConversationEvent: false,
          });
          break;
        default:
          break;
      }
    },
    getColor(index) {
      switch (index) {
        case 0:
          return '#49B77D';
        case 1:
          return '#ffa800';
        default:
          return '#62697C';
      }
    },
  },
};
</script>

<style lang="scss">
@import '~dashboard/assets/scss/woot';

.c-app-bar {
  z-index: var(--z-index-low);

  &__link {
    color: initial;

    &:hover {
      color: initial;
    }
  }
}
</style>
